const config = {
    NODE_ENV: process.env.NODE_ENV || 'development',
    API_URL: process.env.REACT_APP_API_URL || '',
    HOST_URL: process.env.REACT_APP_HOST_URL || '',
    PORTIVE_AUTH_TOKEN: process.env.REACT_APP_PORTIVE_AUTH_TOKEN || '',
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',
    TRACKS_TAB_SWITCHING: process.env.NODE_ENV !== 'development',
};

export { config };
