import { styled } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { pathHandler } from '../../lib/pathHandler';
import { TextLink } from '../TextLink';

function EstablishmentSideItemMenu(props: {
    establishment: { id: string; name: string };
    isActive: boolean;
}) {
    const path = pathHandler.getRoutePath('ESTABLISHMENT', {
        establishmentId: props.establishment.id,
    });
    return (
        <Container>
            <IconContainer>
                <AccountBalanceOutlinedIcon fontSize={'medium'} />
            </IconContainer>
            <TextLink
                variant="h5"
                isHighlighted={props.isActive}
                to={path}
                label={props.establishment.name}
            />
        </Container>
    );
}

function ClasseSideItemMenu(props: {
    establishmentId: string;
    classe: { id: string; name: string };
    isActive: boolean;
}) {
    const path = pathHandler.getRoutePath('CLASSE', {
        establishmentId: props.establishmentId,
        classeId: props.classe.id,
    });
    return (
        <Container>
            <IconContainer>
                <GroupsIcon fontSize="small" />
            </IconContainer>
            <TextLink
                variant="h6"
                isHighlighted={props.isActive}
                to={path}
                label={props.classe.name}
            />
        </Container>
    );
}

export { EstablishmentSideItemMenu, ClasseSideItemMenu };

const IconContainer = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
}));

const Container = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
}));
