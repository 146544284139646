import { styled, Typography } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';

function TextLink(props: {
    to: string;
    label: string;
    isHighlighted?: boolean;
    smallLabel?: string;
    opensNewTab?: boolean;
    onClick?: () => void;
    variant?: 'h5' | 'h6';
}) {
    const target = props.opensNewTab ? '_blank' : undefined;
    const isThereSmallLabel = props.smallLabel !== undefined;

    return (
        <StyledLink target={target} to={props.to} onClick={props.onClick}>
            {isThereSmallLabel && (
                <SmallLabel variant={props.variant} isHighlighted={props.isHighlighted}>
                    {props.smallLabel}
                </SmallLabel>
            )}
            <Label
                variant={props.variant}
                isHighlighted={props.isHighlighted}
                isThereSmallLabel={isThereSmallLabel}
            >
                {props.label}
            </Label>
        </StyledLink>
    );
}

export { TextLink };

const Label = styled(Typography)<{ isThereSmallLabel: boolean; isHighlighted?: boolean }>(
    ({ theme, isThereSmallLabel, isHighlighted }) => ({
        fontWeight: isHighlighted ? 'bold' : undefined,

        [theme.breakpoints.down('lg')]: {
            display: isThereSmallLabel ? 'none' : 'inherit',
        },
    }),
);
const SmallLabel = styled(Typography)<{ isHighlighted?: boolean }>(({ theme, isHighlighted }) => ({
    fontWeight: isHighlighted ? 'bold' : undefined,

    [theme.breakpoints.up('lg')]: { display: 'none' },
}));

const StyledLink = styled(ReactRouterLink)<{ isHighlighted?: boolean }>(
    ({ theme, isHighlighted }) => ({
        fontWeight: isHighlighted ? 'bold' : undefined,
        color: theme.palette.common.black,
        display: 'block',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    }),
);
