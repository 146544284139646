import { styled, TextField } from '@mui/material';
import { Modal } from '../../../components/Modal';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { supportApi } from '../../../lib/api/supportApi';
import { useAlert } from '../../../lib/alert';

const DEFAULT_ENQUIRY = `Bonjour,
Je souhaiterais établir un devis pour l'utilisation de Tactic par mon établissement .........
Mon besoin est le suivant : .......
Bien cordialement
`;

function AskQuoteModal(props: { isOpen: boolean; close: () => void }) {
    const [email, setEmail] = useState('');
    const [enquiry, setEnquiry] = useState(DEFAULT_ENQUIRY);
    const isConfirmDisabled = !email || !enquiry;
    const { displayAlert } = useAlert();
    const askQuoteMutation = useMutation({
        mutationFn: supportApi.askQuote,
        onSuccess: () => {
            displayAlert({
                variant: 'success',
                text: `Votre demande de devis a bien été soumise`,
            });
            props.close();
        },
        onError: (error) => {
            console.error(error);
            displayAlert({
                variant: 'error',
                text: 'Une erreur est survenue. Votre demande de devis ne nous est pas parvenue.',
            });
        },
    });

    return (
        <Modal
            size="medium"
            title="Demander un devis"
            isOpen={props.isOpen}
            close={props.close}
            isConfirmDisabled={isConfirmDisabled}
            onConfirm={onConfirm}
            isConfirmLoading={askQuoteMutation.isPending}
        >
            <TextFieldContainer>
                <TextField
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    name="email"
                    type="email"
                    label="Votre adresse e-mail"
                    required
                    fullWidth
                />
            </TextFieldContainer>
            <TextFieldContainer>
                <TextField
                    name="enquiry"
                    label="Votre demande"
                    value={enquiry}
                    onChange={(event) => setEnquiry(event.target.value)}
                    required
                    multiline
                    fullWidth
                    rows={5}
                />
            </TextFieldContainer>
        </Modal>
    );

    function onConfirm() {
        askQuoteMutation.mutate({ email, enquiry });
    }
}

const TextFieldContainer = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(1),
    flex: 1,
    width: '100%',
}));

export { AskQuoteModal };
