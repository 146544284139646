import { DELAY_BEFORE_CONSIDERING_TIME_IS_ALMOST_UP } from '../constants';
import { computeOfficialEndTime } from './computeOfficialEndTime';

function computeExamCaption({
    duration,
    startedAt,
    isTimeAlmostUp,
}: {
    duration: number | null;
    startedAt: string;
    isTimeAlmostUp: boolean;
}): { title: string; subtitle: string } | undefined {
    if (duration === null) {
        return undefined;
    }
    if (isTimeAlmostUp) {
        return {
            title: `🔔 Il vous reste moins de ${DELAY_BEFORE_CONSIDERING_TIME_IS_ALMOST_UP} secondes 🔔`,
            subtitle: `Veuillez cliquer sur le bouton "Terminer l'examen" en bas à droite.`,
        };
    }
    const officialEndTime = computeOfficialEndTime({
        duration,
        startedAt,
    });

    return {
        title: `Votre examen se terminera à ${officialEndTime}.`,
        subtitle: 'Passé cette heure, aucune soumission ne sera prise en compte.',
    };
}

export { computeExamCaption };
