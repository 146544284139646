import { useSearchParams } from 'react-router-dom';

function useQueryParams<T extends string>(paramNames: readonly T[]) {
    const [searchParams] = useSearchParams();
    const queryParams = paramNames.reduce(
        (acc, paramName) => ({ ...acc, [paramName]: searchParams.get(paramName) || undefined }),
        {} as Record<T, string | undefined>,
    );
    return queryParams;
}

export { useQueryParams };
