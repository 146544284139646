import { Typography, styled } from '@mui/material';
import { ReactNode, useRef } from 'react';
import { QuickScrollArrows } from './QuickScrollArrows';
import { SplitInstruction } from './SplitInstruction';
import { BREADCRUMBS_HEIGHT, HEADER_HEIGHT } from '../../../../constants';
import { FOOTER_HEIGHT } from './constants';
import { useLocation } from 'react-router-dom';
import { pathHandler } from '../../../../lib/pathHandler';
import { ROUTE_ELEMENTS } from '../../../../routes/routeElements';

function TestPageLayout(props: {
    title: string;
    subtitle?: string;
    splitScreenInstruction?: string | undefined;
    closeSplitScreen?: () => void;
    isHeaderBlurred?: boolean;
    studentEmail?: string;
    highlightedResult?: string;
    lowlightedResult?: string;
    children: ReactNode;
    centerElement?: JSX.Element;
    leftElement?: JSX.Element;
    rightButtons?: JSX.Element[];
    shouldPreventTextSelection?: boolean;
}) {
    const location = useLocation();

    const areThereBreadcrumbs = computeAreThereBreadcrumbs(location.pathname);
    const isThereFooter = !!props.centerElement || !!props.rightButtons;
    const ChildrenContainer = props.shouldPreventTextSelection
        ? SelectionLessContainer
        : BasicContainer;
    const { splitScreenInstruction, closeSplitScreen } = props;
    const isScreenSplit = !!splitScreenInstruction && !!closeSplitScreen;
    const titleRef = useRef<HTMLDivElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);
    return (
        <MainContainer>
            {splitScreenInstruction !== undefined && closeSplitScreen && (
                <SplitInstruction instruction={splitScreenInstruction} close={closeSplitScreen} />
            )}

            <PageContainer areThereBreadcrumbs={areThereBreadcrumbs}>
                <ScrollContainer>
                    {<QuickScrollArrows titleRef={titleRef} bottomRef={bottomRef} />}

                    <SheetContainer isScreenSplit={isScreenSplit}>
                        <TitleContainer ref={titleRef}>
                            <Title variant="h2">{props.title}</Title>
                            {!!props.subtitle && <Subtitle variant="h5">{props.subtitle}</Subtitle>}
                        </TitleContainer>
                        <StudentInfoContainer isBlurred={!!props.isHeaderBlurred}>
                            <StudentEmailContainer>
                                {!!props.studentEmail && (
                                    <StudentEmail>
                                        Adresse e-mail : {props.studentEmail}
                                    </StudentEmail>
                                )}
                            </StudentEmailContainer>
                            {!!props.highlightedResult && (
                                <ResultContainer>
                                    {
                                        <Typography variant="caption">
                                            {props.highlightedResult}
                                        </Typography>
                                    }
                                    {props.lowlightedResult && (
                                        <Typography variant="h6">
                                            {props.lowlightedResult}
                                        </Typography>
                                    )}
                                </ResultContainer>
                            )}
                        </StudentInfoContainer>
                        <ChildrenContainer>{props.children}</ChildrenContainer>
                        <BottomContainer ref={bottomRef}></BottomContainer>
                    </SheetContainer>
                </ScrollContainer>
                {isThereFooter && (
                    <FooterContainer>
                        <LeftFooterPart>{props.leftElement}</LeftFooterPart>
                        <CenterFooterPart>{props.centerElement}</CenterFooterPart>
                        <RightFooterPart>{props.rightButtons}</RightFooterPart>
                    </FooterContainer>
                )}
            </PageContainer>
        </MainContainer>
    );

    function computeAreThereBreadcrumbs(pathname: string) {
        const parsedPath = pathHandler.parsePath(pathname);
        if (!parsedPath) {
            return false;
        }
        const areThereBreadcrumbs =
            ROUTE_ELEMENTS[parsedPath.routeKey].authorizedRole === 'teacher';
        return areThereBreadcrumbs;
    }
}

const FooterContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    height: FOOTER_HEIGHT,
    position: 'fixed',
    width: '100%',
    backgroundColor: 'white',
    borderTop: `1px solid ${theme.palette.common.black}`,
    bottom: 0,
    left: 0,
}));

const BottomContainer = styled('div')({});

const CenterFooterPart = styled('div')({
    height: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const RightFooterPart = styled('div')(({ theme }) => ({
    height: '100%',
    flex: 1,
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
}));

const LeftFooterPart = styled('div')({
    height: '100%',
    flex: 1,
    display: 'flex',
});

const MainContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
}));

const StudentInfoContainer = styled('div')<{ isBlurred: boolean }>(({ theme, isBlurred }) => ({
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    filter: isBlurred ? 'blur(10px)' : 'none',
    userSelect: isBlurred ? 'none' : 'inherit',
}));

const StudentEmail = styled(Typography)(({ theme }) => ({
    fontStyle: 'italic',
}));
const StudentEmailContainer = styled('div')(({ theme }) => ({
    fontStyle: 'italic',
}));

const Title = styled(Typography)({ textAlign: 'center' });
const Subtitle = styled(Typography)({ fontStyle: 'italic' });

const TitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
}));

const PageContainer = styled('div')<{ areThereBreadcrumbs: boolean }>(
    ({ theme, areThereBreadcrumbs }) => ({
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        height: `calc(100vh - ${HEADER_HEIGHT}px - ${
            areThereBreadcrumbs ? BREADCRUMBS_HEIGHT : 0
        }px)`,
    }),
);

const ScrollContainer = styled('div')(({ theme }) => ({
    overflowY: 'scroll',
    flex: 1,
    paddingTop: theme.spacing(1),
}));

const SheetContainer = styled('div')<{ isScreenSplit: boolean }>(({ theme, isScreenSplit }) => ({
    paddingTop: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
        width: isScreenSplit ? '80%' : '55%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
        width: '80%',
        padding: theme.spacing(1),
    },
    // overflowY: 'scroll',
    margin: 'auto',
    marginBottom: FOOTER_HEIGHT,
    borderRadius: 2,
    border: `solid ${theme.palette.common.black} 1px`,
    boxShadow: theme.shadows[4],
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
}));

const BasicContainer = styled('div')(({ theme }) => ({ paddingTop: theme.spacing(3) }));
const SelectionLessContainer = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(3),
    userSelect: 'none',
}));
const ResultContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
});

export { TestPageLayout };
