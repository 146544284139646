import { styled } from '@mui/material';
import { TextLink } from '../../../components/TextLink';

function AuthenticationFooter(props: { label: string; to: string }) {
    return (
        <Container>
            <TextLink label={props.label} to={props.to} />
        </Container>
    );
}

const Container = styled('div')(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.grey['200']}`,
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
}));
export { AuthenticationFooter };
