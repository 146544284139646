import CloseIcon from '@mui/icons-material/Close';
import { styled, Typography } from '@mui/material';
import Markdown from 'react-markdown';
import { IconButton } from '../../../../components/IconButton';
import { FOOTER_HEIGHT } from './constants';
import { HEADER_HEIGHT } from '../../../../constants';

function SplitInstruction(props: { instruction: string; close: () => void }) {
    return (
        <Container>
            <ButtonContainer>
                <IconButton
                    IconComponent={CloseIcon}
                    title="Fermer la vue partagée"
                    onClick={props.close}
                />
            </ButtonContainer>
            <TextContainer>
                <Typography>
                    <Markdown className="exercise-markdown">{props.instruction}</Markdown>
                </Typography>
            </TextContainer>
        </Container>
    );
}

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    position: 'relative',
    flex: 1,
    background: `white`,
    borderRight: `1px ${theme.palette.common.black} dashed`,
}));

const TextContainer = styled('div')(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: FOOTER_HEIGHT,
    overflowY: 'scroll',
}));

const ButtonContainer = styled('div')(({ theme }) => ({
    position: 'sticky',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    left: 0,
    top: 0,
}));

export { SplitInstruction };
