import { BASE_URL } from './constants';
import { performApiCall } from './lib/performApiCall';

const supportApi = {
    askQuote,
};

async function askQuote(params: { email: string; enquiry: string }): Promise<{ ok: boolean }> {
    const URL = `${BASE_URL}/support/ask-quote`;
    return performApiCall(URL, 'POST', params);
}

export { supportApi };
