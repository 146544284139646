import { LinearProgress, styled, Tooltip, Typography } from '@mui/material';

const BAR_THICKNESS = 7;

function ProgressBar(props: { progress: number; hideValue?: boolean; width?: string }) {
    const displayedProgress = `${props.progress} %`;
    return (
        <ProgressWithLabelContainer width={props.width}>
            {!props.hideValue && <Typography variant="body2">{displayedProgress}</Typography>}
            <ProgressContainer placement="right" title={displayedProgress}>
                <Progress color="primary" variant="determinate" value={props.progress} />
            </ProgressContainer>
        </ProgressWithLabelContainer>
    );
}

const ProgressWithLabelContainer = styled('div')<{ width?: string }>(({ width }) => ({
    width: width || '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
}));

const ProgressContainer = styled(Tooltip)(({ theme }) => ({
    width: '100%',
}));

const Progress = styled(LinearProgress)(({ theme }) => ({ height: BAR_THICKNESS }));

export { ProgressBar };
