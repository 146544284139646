const ESTABLISHMENT_LIST = [
    'Université de Tours',
    'Université Sorbonne Paris Nord',
    'Université Paris-Saclay',
    'Université de Nantes',
    'Université Bordeaux Montaigne',
    'Université de la Réunion',
    "Université d'Angers",
    'Université de Franche-Comté',
    'Université de Rouen Normandie',
    'Université de Lorraine',
    "Université d'Orléans",
    'Université Paris Cité',
    'Université Grenoble Alpes',
    'Université de Bourgogne',
    'Université Jean Moulin Lyon 3',
    'Sciences Po Paris',
    'Université de Strasbourg',
    'Sorbonne Université',
    'Université de Toulouse',
    'Université Paris Dauphine – PSL',
    'Université Paris Nanterre',
    'Université Rennes 2',
    'Université Bretagne Sud',
    'École des Ponts ParisTech',
    'Université de Lille',
];

export { ESTABLISHMENT_LIST };
