import { Autocomplete, TextField } from '@mui/material';
import { ESTABLISHMENT_LIST } from './constants';

function EstablishmentAutocomplete(props: {
    establishmentName: string;
    setEstablishmentName: (establishmentName: string) => void;
}) {
    return (
        <Autocomplete
            disablePortal
            freeSolo
            onChange={(_event, establishmentName) => {
                props.setEstablishmentName(establishmentName || '');
            }}
            value={props.establishmentName}
            options={ESTABLISHMENT_LIST}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required
                    fullWidth
                    onChange={(event) => props.setEstablishmentName(event.target.value)}
                    name="establishment"
                    label="Établissement principal"
                />
            )}
        />
    );
}

export { EstablishmentAutocomplete };
