import { useMemo } from 'react';
import { config } from '../config';
import { pathHandler } from '../lib/pathHandler';
import { Link } from 'react-router-dom';
import { localSessionHandler } from '../lib/localSessionHandler';

const HEIGHT = 35;

const logoUrlMapping = {
    mini: `${config.API_URL}/static/images/logo_mini.png`,
    full: `${config.API_URL}/static/images/full_logo.png`,
};

type logoVariantType = 'mini' | 'full';

function Logo(props: { variant: logoVariantType; shouldRedirectOnClick?: boolean }) {
    const isAuthenticated = localSessionHandler.getIsAuthenticated();
    const pathToRedirectTo = useMemo(
        () => computePathToRedirectTo(isAuthenticated),
        [isAuthenticated],
    );
    if (!props.shouldRedirectOnClick) {
        return renderImage(props.variant);
    }
    return <Link to={pathToRedirectTo}>{renderImage(props.variant)}</Link>;
}

function renderImage(variant: logoVariantType) {
    return <img src={logoUrlMapping[variant]} alt="Logo Tactic" height={HEIGHT} />;
}

function computePathToRedirectTo(isAuthenticated: boolean): string {
    if (isAuthenticated) {
        return pathHandler.getRoutePath('TEACHER_HOME');
    } else {
        return pathHandler.getRoutePath('HOME');
    }
}

export type { logoVariantType };
export { Logo };
