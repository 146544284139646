import { styled } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IconButton } from '../../../../components/IconButton';

const CRISP_RIGHT_OFFSET = 34;
const CRISP_BOTTOM_OFFSET = 88;

function QuickScrollArrows(props: {
    titleRef: React.RefObject<HTMLDivElement>;
    bottomRef: React.RefObject<HTMLDivElement>;
}) {
    return (
        <Container>
            <IconContainer>
                <IconButton
                    disabled={!props.titleRef}
                    title="Aller en haut"
                    placement="left"
                    onClick={scrollToTop}
                    IconComponent={ArrowUpwardIcon}
                />
            </IconContainer>
            <IconContainer>
                <IconButton
                    disabled={!props.bottomRef}
                    placement="left"
                    title="Aller en bas"
                    onClick={scrollToBottom}
                    IconComponent={ArrowDownwardIcon}
                />
            </IconContainer>
        </Container>
    );

    function scrollToTop() {
        props.titleRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    function scrollToBottom() {
        props.bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
}

export { QuickScrollArrows };

const Container = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        right: 0,
        bottom: CRISP_BOTTOM_OFFSET,
    },
    [theme.breakpoints.up('md')]: {
        bottom: CRISP_BOTTOM_OFFSET, // to fit in the middle of the crisp icon,
        right: CRISP_RIGHT_OFFSET, // to fit in the middle of the crisp icon
    },
    position: 'fixed',
}));
const IconContainer = styled('div')({});
