// compute remaining time in seconds
function computeRemainingTime(
    { startedAt, duration }: { startedAt: string; duration: number | null },
    now: Date,
) {
    if (duration === null) {
        return Infinity;
    }
    const startedAtDate = new Date(startedAt);
    const officialEndTimestamp = startedAtDate.getTime() + duration * 60 * 1000;

    return Math.floor((officialEndTimestamp - now.getTime()) / 1000);
}

export { computeRemainingTime };
