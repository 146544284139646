import { useEffect, useState } from 'react';
import { StudentPage } from '../../../components/StudentPage';
import { styled, Typography } from '@mui/material';
import { QuestionsAnswering } from './QuestionsAnswering';
import { computeRemainingTime } from './lib/computeRemainingTime';
import { useNavigate } from 'react-router-dom';
import { attemptWithoutAnswersType } from './types';
import { time } from '../../../lib/time';
import { computeExamCaption } from './lib/computeExamCaption';
import { DELAY_BEFORE_CONSIDERING_TIME_IS_ALMOST_UP } from './constants';

function ExamTakingPage(props: {
    attemptWithoutAnswers: attemptWithoutAnswersType;
    examDonePath: string;
}) {
    const navigate = useNavigate();

    const remainingTime = computeRemainingTime(
        {
            startedAt: props.attemptWithoutAnswers.startedAt,
            duration: props.attemptWithoutAnswers.exam.duration,
        },
        new Date(),
    );

    const [isTimeAlmostUp, setIsTimeAlmostUp] = useState(
        remainingTime < DELAY_BEFORE_CONSIDERING_TIME_IS_ALMOST_UP,
    );
    const [remainingTimeTimeout, setRemainingTimeTimeout] = useState<NodeJS.Timeout | null>(null);

    const examCaption = computeExamCaption({
        duration: props.attemptWithoutAnswers.exam.duration,
        startedAt: props.attemptWithoutAnswers.startedAt,
        isTimeAlmostUp,
    });

    const lastUpdatedAt = computeReadableUpdatedAt(props.attemptWithoutAnswers.updatedAt);

    useEffect(() => {
        if (remainingTime === Infinity || remainingTime < 0 || isTimeAlmostUp) {
            return;
        }

        if (remainingTime < DELAY_BEFORE_CONSIDERING_TIME_IS_ALMOST_UP) {
            setIsTimeAlmostUp(true);
            return;
        }
        if (!!remainingTimeTimeout) {
            return;
        }
        if (remainingTime !== Infinity) {
            const remainingTimeBeforeAlerting =
                remainingTime - DELAY_BEFORE_CONSIDERING_TIME_IS_ALMOST_UP;
            const newRemainingTimeTimeout = setTimeout(
                () => setIsTimeAlmostUp(true),
                remainingTimeBeforeAlerting * 1000,
            );
            setRemainingTimeTimeout(newRemainingTimeTimeout);
        }
    }, [remainingTime, isTimeAlmostUp, remainingTimeTimeout]);

    return (
        <StudentPage
            title={
                !!examCaption ? (
                    <TitleContainer>
                        <Title
                            isTimeAlmostUp={isTimeAlmostUp}
                            className={isTimeAlmostUp ? 'urgent-text' : undefined}
                            variant="h3"
                        >
                            {examCaption.title}
                        </Title>
                        <Subtitle isTimeAlmostUp={isTimeAlmostUp} variant="h6">
                            {examCaption.subtitle}
                        </Subtitle>
                    </TitleContainer>
                ) : undefined
            }
        >
            <ExamPageContainer>
                <QuestionsAnswering
                    lastUpdatedAt={lastUpdatedAt}
                    studentEmail={props.attemptWithoutAnswers.studentEmail}
                    title={props.attemptWithoutAnswers.exam.name}
                    remainingTime={remainingTime}
                    exercises={props.attemptWithoutAnswers.exam.exercises}
                    attemptId={props.attemptWithoutAnswers.id}
                    onExamDone={onExamDone}
                />
            </ExamPageContainer>
        </StudentPage>
    );

    function onExamDone() {
        navigate(props.examDonePath);
    }

    function computeReadableUpdatedAt(updatedAt: string | undefined) {
        if (!updatedAt) {
            return undefined;
        }
        return time.formatToReadableTime(updatedAt, { hideSeconds: true });
    }
}

const TitleContainer = styled('div')({
    flexDirection: 'column',
    textAlign: 'center',
});

const Title = styled(Typography)<{ isTimeAlmostUp: boolean }>(({ theme, isTimeAlmostUp }) => ({
    color: isTimeAlmostUp ? `${theme.palette.error.main}` : 'inherit',
}));
const Subtitle = styled(Typography)<{ isTimeAlmostUp: boolean }>(({ theme, isTimeAlmostUp }) => ({
    fontStyle: 'italic',
    color: isTimeAlmostUp ? `${theme.palette.error.main}` : 'inherit',
}));

const ExamPageContainer = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
}));

export { ExamTakingPage };
