import { Checkbox, FormControlLabel, TextField, Typography, styled } from '@mui/material';
import { NotLoggedInPage } from '../../../components/NotLoggedInPage';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useAlert } from '../../../lib/alert';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { pathHandler } from '../../../lib/pathHandler';
import { LoadingButton } from '@mui/lab';
import { localSessionHandler } from '../../../lib/localSessionHandler';
import { usersApi } from '../../../lib/api/usersApi';
import { Link } from 'react-router-dom';
import { EstablishmentAutocomplete } from './EstablishmentAutocomplete';
import { AuthenticationFooter } from '../components/AuthenticationFooter';
import { useQueryParams } from '../../../lib/useQueryParams';

function SignUp() {
    const queryParams = useQueryParams(['email', 'packageId']);
    const [email, setEmail] = useState(queryParams.email || '');
    const [password, setPassword] = useState('');
    const [establishmentName, setEstablishmentName] = useState('');
    const [classeName, setClasseName] = useState('');
    const [isCGVChecked, setIsCGVChecked] = useState(false);
    const navigate = useNavigate();

    const { displayAlert } = useAlert();

    const mutation = useMutation({
        mutationFn: usersApi.createUser,
        onSuccess: (data) => {
            const { token, userInfo, establishmentId, classeId } = data;
            localSessionHandler.setToken(token);
            localSessionHandler.setUserInfo(userInfo);
            let nextSearchParams = {
                ...queryParams,
                establishmentId,
                classeId,
            };

            navigate(pathHandler.getRoutePath('TEACHER_HOME', {}, nextSearchParams));
        },
        onError: () => {
            displayAlert({
                variant: 'error',
                text: 'Une erreur est survenue.',
            });
        },
    });

    return (
        <NotLoggedInPage>
            <ContentContainer>
                <Card size="medium">
                    <CardContent onSubmit={handleSubmit}>
                        <TitleContainer>
                            <Typography variant="h2">Créer un compte</Typography>
                        </TitleContainer>
                        <FieldsContainer>
                            <FieldContainer>
                                <TextField
                                    required
                                    autoFocus
                                    fullWidth
                                    name="email"
                                    type="email"
                                    label="Adresse e-mail"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    type="password"
                                    label="Mot de passe"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <EstablishmentAutocomplete
                                    establishmentName={establishmentName}
                                    setEstablishmentName={setEstablishmentName}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <TextField
                                    required
                                    fullWidth
                                    name="classe"
                                    type="text"
                                    label="Classe principale"
                                    placeholder="Exemple: A2"
                                    value={classeName}
                                    onChange={(event) => setClasseName(event.target.value)}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FormControlLabel
                                    required
                                    control={
                                        <Checkbox checked={isCGVChecked} onChange={onChangeCGV} />
                                    }
                                    label={
                                        <Typography>
                                            J'ai lu et j'accepte les 
                                            <Link
                                                target="_blank"
                                                to={pathHandler.getRoutePath(
                                                    'TERMS_AND_CONDITIONS_OF_SALE',
                                                )}
                                            >
                                                Conditions Générales de Vente
                                            </Link>
                                        </Typography>
                                    }
                                />
                            </FieldContainer>
                        </FieldsContainer>
                        <LoadingButton
                            loading={mutation.isPending}
                            type="submit"
                            variant="contained"
                            disabled={
                                !password ||
                                !email ||
                                !establishmentName ||
                                !classeName ||
                                !isCGVChecked
                            }
                        >
                            Créer un compte
                        </LoadingButton>
                        <AuthenticationFooter
                            label="Vous avez déjà un compte ? Cliquez ici pour vous connecter"
                            to={pathHandler.getRoutePath('SIGN_IN', {}, queryParams)}
                        />
                    </CardContent>
                </Card>
            </ContentContainer>
        </NotLoggedInPage>
    );

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        mutation.mutate({ email, password, establishmentName, classeName });
        event.preventDefault();
    }

    function onChangeCGV(_event: ChangeEvent<HTMLInputElement>, checked: boolean) {
        setIsCGVChecked(checked);
    }
}

const ContentContainer = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});

const CardContent = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
}));

const FieldsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
}));
const FieldContainer = styled('div')(({ theme }) => ({ marginBottom: theme.spacing(2) }));
const TitleContainer = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(6),
    textAlign: 'center',
}));

export { SignUp };
