import { Navigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { StudentPage } from '../../../components/StudentPage';
import { Loader } from '../../../components/Loader';
import { attemptWithoutAnswersType } from './types';
import { computeShouldNavigateToExamDone } from './lib/computeShouldNavigateToExamDone';
import { pathHandler } from '../../../lib/pathHandler';
import { useEffect } from 'react';
import { cheatingHandler } from '../../../lib/cheatingHandler';
import { eventHandler } from '../../../lib/eventHandler';
import { useAlert } from '../../../lib/alert';
import { computePathKeyToNavigateTo } from '../../../lib/computePathKeyToNavigateTo';
import { attemptsApi } from '../../../lib/api/attemptsApi';
import { ExamTakingPage } from './ExamTakingPage';
import { config } from '../../../config';

function ExamTaking() {
    const params = useParams();
    const attemptId = params.attemptId as string;
    const studentId = params.studentId as string;
    const query = useQuery<attemptWithoutAnswersType>({
        queryKey: ['attempts', attemptId, 'without-answers'],
        queryFn: () => attemptsApi.getAttemptWithoutAnswers(attemptId),
    });
    const { displayAlert } = useAlert();

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            const pathname = (event.currentTarget as Window).location.pathname;
            const attemptId = pathHandler.extractCurrentAttemptId(pathname);
            if (!!attemptId) {
                event.returnValue = null;
            }
        };
        if (config.TRACKS_TAB_SWITCHING) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        const handleBlur = eventHandler.buildHandleWindowEvent(
            cheatingHandler.buildOnFocusChangeCallback('blur', () =>
                displayAlert({
                    variant: 'warning',
                    text: "Vous êtes sorti de la page. Veuillez revenir sur la page de l'examen.",
                }),
            ),
        );

        if (config.TRACKS_TAB_SWITCHING) {
            window.addEventListener('blur', handleBlur);
        }
        const handleFocus = eventHandler.buildHandleWindowEvent(
            cheatingHandler.buildOnFocusChangeCallback('focus', () =>
                displayAlert({
                    variant: 'warning',
                    text: "Attention, les sorties de la page d'examen sont interdites. Votre professeur·e en a été averti·e",
                }),
            ),
        );
        if (config.TRACKS_TAB_SWITCHING) {
            window.addEventListener('focus', handleFocus);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('blur', handleFocus);
            window.removeEventListener('focus', handleFocus);
        };
    }, [displayAlert]);

    if (!query.data) {
        return (
            <StudentPage>
                <Loader />
            </StudentPage>
        );
    }
    const pathKeyToNavigateTo = computePathKeyToNavigateTo(query.data, 'take');

    if (pathKeyToNavigateTo !== 'EXAM_TAKING') {
        const pathToNavigateTo = pathHandler.getRoutePath(pathKeyToNavigateTo, {
            studentId,
            attemptId: query.data.id,
        });
        return <Navigate to={pathToNavigateTo} />;
    }

    const examId = query.data.exam.id;

    const examDonePath = pathHandler.getRoutePath('EXAM_DONE', { examId });

    const shouldNavigateToExamDone = computeShouldNavigateToExamDone(new Date(), {
        duration: query.data.exam.duration,
        endedAt: query.data.endedAt,
        extraTime: query.data.exam.extraTime,
        startedAt: query.data.startedAt,
    });

    if (shouldNavigateToExamDone) {
        return <Navigate to={examDonePath} />;
    }

    return <ExamTakingPage attemptWithoutAnswers={query.data} examDonePath={examDonePath} />;
}

export { ExamTaking };
